<template>
  <v-card  id="stepfooter-contact-page" class="stepfooter-page ma-0 pa-3 d-flex align-center justify-space-between" tile color="primary">
    <v-row class="ma-0 pa-0">
      <v-col cols="4" class="pa-0 d-flex align-center">
        <v-btn v-if="currentPage !== 1" color="secondary" class="font-weight-bold"
          elevation="2" @click="$emit('back')" rounded>
            <v-icon left dark>mdi-chevron-left</v-icon> Back
        </v-btn>
      </v-col>
      <v-col cols="4" class="pa-0 d-flex justify-center align-center">
        <div class="font-weight-medium mr-6 text-caption white--text text-capitalize cursor-pointer" @click="resetForm">
          <div class="d-flex flex-column justify-center align-center">
            <v-icon left dark color="iconbg" class="mb-1 mr-0">mdi-refresh</v-icon>
            <div>Restart</div>
          </div>
        </div>
      </v-col>
      <v-col cols="4" class="pa-0 d-flex align-center justify-end">
        <v-btn color="secondary" class="font-weight-bold"
          elevation="2" type="submit" :loading="contactLoading" rounded :disabled="contactLoading || !selectedDonor.id">
          {{currentPage === 2 ? 'Save Contact' : 'Next'}}
            <v-icon right dark>mdi-chevron-right</v-icon>
        </v-btn>
      </v-col>
    </v-row>
  </v-card>
</template>

<script>
import { mapGetters } from "vuex";

export default {
  name: "stepFooter",
  props: ["currentPage"],
  emits: ["goHome"],
  computed: {
    ...mapGetters(["model", "contactLoading", "selectedDonor"])
  },
  methods: {
    resetForm() {
      this.$store.commit("setModel", {});
      this.$store.commit("setSelectedDonor", {});
      this.$store.commit("setSearchLists", []);
      this.$emit('goHome');
    }
  }
};
</script>

